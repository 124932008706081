import consumer from './consumer';

window.subscribeToWebOverdueChannel = function(user_id){

    //console.log(`Connecting to WebOverdueChannel_${user_id}`);
    consumer.subscriptions.create({channel: "WebTaskOverdueChannel", user_id: user_id}, {
        connected() {
            // Called when the subscription is ready for use on the server
            //console.log(`connected to WebTaskOverdueChannel_${user_id}`)
        },

        disconnected() {
            // Called when the subscription has been terminated by the server
            //console.log(`disconnected from WebTaskOverdueChannel_${user_id}`)
        },

        received(data) {
            //console.log('received')
            $('.fixed-notification-box-wrap').show()
            $($(document).find('.fixed-notification-box-wrap')).prepend(data.html)
            alert();
            remove_notification();
            add_badge_count(data);
        },
    });

    function alert(){
        const audio = new Audio('/assets/open-ended.ogg');
        audio.play();
    }

    function remove_notification(){
        setTimeout(function() {
            $('.fixed-notification-box-wrap').hide();
        }, 10000);
        setTimeout(function() {
            $('.fixed-notification-box-wrap').find('.fixed-notification-box').remove()
        }, 10000);
    }

    function add_badge_count(data){
        //console.log('add_badge_count', data);
        $('.notification-count').each(function(key, value){
            const el = $(this);
            // all_count is set when more than 3 notifications are send together, otherwise we increase by 1
            el.text(Number(el.text()) + (data.all_count? data.all_count : 1))
        });
        let $notification = $('.notification-task-overdue-count');
        $notification.text(Number($notification.text())+1)
        $('.notification-task-overdue-created-at').text(moment(data.created_before).fromNow())
    }

}




