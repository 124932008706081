import consumer from './consumer';

//console.log('Connecting to WebNotificationsChannel');
consumer.subscriptions.create('WebNotificationsChannel', {

  connected() {
    // Called when the subscription is ready for use on the server
    //console.log('connected to WebNotificationsChannel')
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    $('.fixed-notification-box-wrap').show()
    $($(document).find('.fixed-notification-box-wrap')).prepend(data.html)
    alert();
    remove_notification();
    add_badge_count(data);
  }
});

function alert(){
  const audio = new Audio('/assets/open-ended.ogg');
  audio.play();
}

function remove_notification(){
  setTimeout(function() {
    $('.fixed-notification-box-wrap').hide();
  }, 10000);
  setTimeout(function() {
    $('.fixed-notification-box-wrap').find('.fixed-notification-box').remove()
  }, 10000);
}

function add_badge_count(data){
  //console.log('add_badge_count', data);
  $('.notification-count').each(function(key, value){
    const el = $(this);
    // all_count is set when more than 3 notifications are send together, otherwise we increase by 1
    el.text(Number(el.text()) + (data.all_count? data.all_count : 1))
  });
  let $notification = $('.notification-new-task-count');
  $notification.text(Number($notification.text())+1)
  $('.notification-new-task-created-at').text(moment(data.created_before).fromNow())
}
